<template>
	<div>
		<ContentHeader title="Laporan Pelayanan Klinik" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="card">
					<div class="card-header">
						<h3 class="card-title"><div style="#007bff">Daftar Laporan</div></h3>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-md-3">
								<div class="card card-outline card-primary">
									<div class="card-header">
										<router-link to="/laporan-pendapatan-harian" class="cursor-pointer">
											<h3 class="card-title">Pendapatan Harian</h3>
										</router-link>		
									</div>
								</div>
							</div>		
							<div class="col-md-3">
								<div class="card card-outline card-primary">
									<div class="card-header">
										<router-link to="/laporan-transaksi-per-periode" class="cursor-pointer">
											<h3 class="card-title">Transaksi Per Periode</h3>
										</router-link>
									</div>
								</div>
							</div>
							<div class="col-md-3">
								<div class="card card-outline card-primary">
									<div class="card-header">
										<router-link to="/laporan-perawatan-per-periode" class="cursor-pointer">
											<h3 class="card-title">Perawatan Per Periode</h3>
										</router-link>
									</div>
								</div>
							</div>
							<div class="col-md-3">
								<div class="card card-outline card-primary">
									<div class="card-header">
										<router-link to="/laporan-per-jenis-bayar" class="cursor-pointer">
											<h3 class="card-title">Laporan Per Jenis Bayar</h3>
										</router-link>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<div class="card card-outline card-primary">
									<div class="card-header">
										<router-link to="/laporan-tindakan-per-periode" class="cursor-pointer">
											<h3 class="card-title">Tindakan Per periode</h3>
										</router-link>
									</div>
								</div>
							</div>
							<div class="col-md-3">
								<div class="card card-outline card-primary">
									<div class="card-header">
										<router-link to="/laporan-antrian-pasien" class="cursor-pointer">
											<h3 class="card-title">Antrian Pasien</h3>
										</router-link>
									</div>
								</div>
							</div>
							<div class="col-md-3">
								<div class="card card-outline card-primary">
									<div class="card-header">
										<router-link to="/laporan-harian" class="cursor-pointer">
											<h3 class="card-title">Laporan Harian</h3>
										</router-link>
									</div>
								</div>
							</div>
							<div class="col-md-3">
								<div class="card card-outline card-primary">
									<div class="card-header">
										<router-link to="/laporan-appointment-pasien" class="cursor-pointer">
											<h3 class="card-title">Laporan Appointment Pasien</h3>
										</router-link>
									</div>
								</div>
							</div>
							<div class="col-md-3">
								<div class="card card-outline card-primary">
									<div class="card-header">
										<router-link to="/laporan-kurang-bayar" class="cursor-pointer">
											<h3 class="card-title">Laporan Kurang Bayar</h3>
										</router-link>
									</div>
								</div>
							</div>
							<div class="col-md-3">
								<div class="card card-outline card-primary">
									<div class="card-header">
										<router-link to="/laporan-diagnosa" class="cursor-pointer">
											<h3 class="card-title">Laporan Diagnosa</h3>
										</router-link>
									</div>
								</div>
							</div>
							<div class="col-md-3">
								<div class="card card-outline card-primary">
									<div class="card-header">
										<router-link to="/laporan-penyakit" class="cursor-pointer">
											<h3 class="card-title">Laporan Penyakit</h3>
										</router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ContentHeader from '@/components/ContentHeader'

export default {
	components: {
		ContentHeader
	}
}
</script>